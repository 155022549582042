import * as React from "react";
import "tippy.js/dist/tippy.css";
import "./Wrapper.css";
import { stage } from "src/utils/helpers";

import { useLocation } from "react-router-dom";

export const NavWrapper: React.FunctionComponent<React.PropsWithChildren<{}>> =
  (props) => {
    const { search } = useLocation();
    React.useEffect(() => {
      const fallback = new URLSearchParams(search).get("fallback"); // Fallback url
      if (fallback) {
        // window.location.href = "https://get.dolla.nz/";
      }
    }, [search]);
    return (
      <div className="Wrapper">
        <header>
          <nav>
            <a
              href={`https://widget.${
                stage === "prod" ? "" : `${stage}.`
              }dolla.id`}
            >
              <img
                className="logo"
                src="https://static.dolla.nz/images/icon.png"
                alt="Dolla Logo"
              />
            </a>
            <div className="links">
              <a href="https://get.dolla.nz">Get Dolla</a>
            </div>
          </nav>
        </header>
        <section>
          <div
            className="content"
            style={{
              justifyContent: "flex-start",
            }}
          >
            {props.children}
          </div>
        </section>
        <footer>
          <div className="footerWrapper">
            <div className="store">
              <p>
                <a href="https://www.dolla.nz/legal/privacy-policy">
                  Privacy Policy
                </a>
                {" | "}
                <a href="https://www.dolla.nz/legal/terms-of-service">
                  Terms of Service
                </a>
                {" | "}
                <a href="mailto:hello@dolla.nz">hello@dolla.nz</a>
              </p>
              <div className="badges">
                <a href="https://apps.apple.com/nz/app/dolla/id1616074822?itsct=apps_box_badge&amp;itscg=30200">
                  <img alt="Download on the App Store" src="/app-store.svg" />
                </a>
                <a href="https://play.google.com/store/apps/details?id=nz.dolla.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                  <img alt="Get it on Google Play" src="/play-store.svg" />
                </a>
              </div>
            </div>
            <span>© {new Date().getFullYear()} Dolla Ltd.</span>
          </div>
        </footer>
      </div>
    );
  };
