// Wrap history push/pop state so they emit events

function wrap(name: "pushState" | "replaceState") {
  const fn: any = window.history[name];
  if (!fn.wrapped) {
    window.history[name] = function () {
      const result = fn.apply(this, arguments);
      const evt = new Event(`_${name}`);
      window.dispatchEvent(evt);
      return result;
    };
    (window.history[name] as any).wrapped = true;
  }
}

export default function init() {
  wrap("pushState");
  wrap("replaceState");
}
