/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import { useSearchParams } from "react-router-dom";
import "./WidgetPage.css";
import { stage } from "src/utils/helpers";

type configurgation = {
  tag?: string;
  reference?: string;
  code?: string;
  amount?: string;
  action?: string;
  floating?: boolean;
};

declare global {
  interface Window {
    _hw: any;
    [key: string]: any;
  }
}

export const WidgetPage: React.FunctionComponent = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const configParams = {} as any;
  searchParams.forEach((value, key) => {
    if (key === "floating") {
      configParams[key] = value === "true";
      return;
    }
    if (key === "amount") {
      configParams[key] = parseInt(value);
      return;
    }
    configParams[key] = value;
  });

  const [config, setConfig] = React.useState<configurgation>({
    tag: "hiddencoffeeco",
    action: "PAY",
    ...(configParams as configurgation),
  });

  const loadWidget = (
    w: Window,
    d: Document,
    s: string,
    o: string,
    f: string
  ): void => {
    w[o] =
      w[o] ||
      function () {
        (w[o].q = w[o].q || []).push(arguments);
      };
    let js: HTMLScriptElement;
    let fjs: Element;
    js = d.createElement(s) as HTMLScriptElement;
    fjs = d.getElementsByTagName(s)[0];
    js.id = o;
    js.src = f;
    js.async = true;
    if (fjs.parentNode) {
      fjs.parentNode.insertBefore(js, fjs);
    }
  };

  React.useEffect(() => {
    if (!document.getElementById("_hw")) {
      loadWidget(
        window,
        document,
        "script",
        "_hw",
        `https://widget.${
          stage === "prod" ? "" : `${stage}.`
        }dolla.id/widget.js`
      );
    }
    window._hw("init", config);
  }, []);

  React.useEffect(() => {
    if (Object.keys(config)) {
      window._hw("config", config);
      const configStringParams = Object.keys(config).reduce((acc, key) => {
        (acc as any)[key] = (config as any)[key].toString();
        return acc;
      }, {});
      setSearchParams(configStringParams);
    }
  }, [config]);

  const configString = Object.keys(config).reduce((acc, key) => {
    if (key === "floating") {
      return acc;
    }
    return (
      acc +
      ((config as any)[key]
        ? `${key}: ${
            key !== "amount"
              ? `"${(config as any)[key]}"`
              : (config as any)[key]
          }, `
        : "")
    );
  }, "");

  return (
    <div className="WidgetPage">
      <h1>Widget Playground</h1>
      <div className="widget-demo">
        {/* {!floating && } */}
        <div id="dolla-widget"></div>
      </div>
      <div className="code-snippet">
        <code>
          <pre>
            {!config.floating && `<div id="dolla-widget"></div>\n`}
            {`<script>
  (function (w, d, s, o, f, js, fjs) {
    w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments); };
    (js = d.createElement(s)), (fjs = d.getElementsByTagName(s)[0]);
    js.id = o; js.src = f; js.async = 1;
    fjs.parentNode.insertBefore(js, fjs);
  })(window, document, "script", "_hw", "https://widget.${
    stage === "prod" ? "" : `${stage}.`
  }dolla.id/widget.js");
  _hw("init", {
    ${configString}
  });
</script>`}
          </pre>
        </code>
      </div>
      <input
        type="text"
        placeholder="Tag"
        value={config.tag}
        onChange={(e) => setConfig({ ...config, tag: e.target.value })}
      />
      <input
        type="text"
        placeholder="Reference (optional)"
        value={config.reference}
        onChange={(e) => setConfig({ ...config, reference: e.target.value })}
      />
      <input
        type="text"
        placeholder="Code (optional)"
        value={config.code}
        onChange={(e) => setConfig({ ...config, code: e.target.value })}
      />
      <input
        type="number"
        step="0.01"
        placeholder="Amount (optional)"
        value={config.amount}
        onChange={(e) => setConfig({ ...config, amount: e.target.value })}
      />
      <select
        onChange={(e) => setConfig({ ...config, action: e.target.value })}
      >
        <option value="PAY">Pay</option>
        <option value="TOP_UP">Top Up</option>
        <option value="FUND">Add funds</option>
        <option value="DONATE">Donate</option>
      </select>
      <div>
        <label htmlFor="floating">Floating?</label>
        <input
          type="checkbox"
          onChange={(e) => setConfig({ ...config, floating: e.target.checked })}
        />
      </div>
    </div>
  );
};
