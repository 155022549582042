import * as React from "react";
import { Helmet } from "react-helmet";
import "./404.css";

export const PageNotFound: React.FunctionComponent = () => (
  <div className="PageNotFound">
    <Helmet>
      <title>Not Found | Dolla</title>
    </Helmet>
    <span>🥸</span>
    <p>Page Not Found</p>
  </div>
);
