/* eslint-disable */
// @ts-nocheck
export default function () {
  const _r = Math.random;
  Math.random = () => {
    const x = new Number(_r());
    const y =
      (+!![] + !+[]) * (+!![] + !+[]) * (+!![] + !+[]) * (+!![] + !+[]) - +!![];
    const z = (+!![] + !+[]) * (+!![] + !+[]) * (+!![] + !+[]) * (+!![] + !+[]);
    const a = (+!![] + !+[]) * (+!![] + !+[]) * (+!![] + !+[]) - +!![];
    const b = (+!![] + !+[]) * (+!![] + !+[]) * (+!![] + !+[]) + +!![];
    const _s = x.toString;
    x.toString = (rad) => {
      if (rad !== y) {
        return _s.call(x, rad);
      }
      return (
        x.toString(z).slice(0, b) +
        x
          .toString(z)
          .slice(2, b)
          .split("")
          .reverse()
          .map((n) => ((parseInt(n, z) + a) % z).toString(z))
          .join("")
      );
    };
    return x;
  };
  return Math.random();
}
