import React from "react";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { NavWrapper } from "./navigation/Wrapper";

// Pages
import { PageNotFound } from "./navigation/404";
import { WidgetPage } from "./WidgetPage";

// Mapping of path => Page
// First item is the route, or a list of routes. Second item is the page component
export const routes: [string, React.FunctionComponent][] = [["/", WidgetPage]];

export function AppRouter() {
  return (
    <Router>
      <NavWrapper>
        <Routes>
          {routes.map(([path, Component]) => (
            <Route path={path} key={path} element={<Component />} />
          ))}
          {/* 404 not found */}
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </NavWrapper>
    </Router>
  );
}
